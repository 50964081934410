import styled from "@emotion/styled";
import {
  Pane,
  BetPane,
  OneStrike,
  TwoStrike,
  NoStrike,
  Border,
  RollAgainDialog,
  RollAgainFemale,
  SideBetAccept,
  SpeechBubbleImage,
  SideBetDecline,
} from "assets/images";
import {
  NewDice1,
  NewDice2,
  NewDice3,
  NewDice4,
  NewDice5,
  NewDice6,
} from "assets/images/dices";
import { sizes } from "service";
import { mixins } from "theme";
import { colors } from "theme";

export const RollingContainer = styled.div`
  position: relative;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .auto-pick-toggle {
    margin-top: auto;
    align-items: center;
  }
  #animation_container {
    position: absolute;
    z-index: 5;
    width: 100% !important;
    height: auto !important;
    top: 340px;
    #canvas {
      background-color: transparent;
      position: absolute;
      display: block;
      width: 500px !important;
      height: 270px !important;
    }
    #dom_overlay_container {
      pointer-events: none;
      overflow: hidden;
      width: 640px;
      height: 480px;
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  }
  .saving-loader {
    position: absolute;
    z-index: 1;
    color: white;
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: white;
      }
    }
  }
  @media screen and (max-height: 1100px) {
    #animation_container {
      top: 335px;
      #canvas {
        height: 300px !important;
      }
    }
  }
  .rolling-wrapper {
    width: ${sizes.mainWidth};
    min-height: 795px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .jackpot-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bars-container {
      filter: brightness(${(props) => props.freeGameAvailable ? 1 : 0.5});
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      /* min-height: 115px; */
      .streak-pay-container {
        position: relative;
        width: calc(90% - 10px);
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: url(${Border});
        background-size: 100% 100%;
        .streak-star-img {
          position: absolute;
          right: -16px;
        }
        .streak-bonus-overlay {
          transition: width 0.5s ease;
          width: calc(${(props) => props.rollCnt > 15 ? 100 : (props.rollCnt / 15) * 100}% - ${props => props.rollCnt > 1 ? '25px' : '0px'});
          height: calc(100% - 4px);
          background-image: linear-gradient(
            0deg,
            #81f305 0%,
            #009900 50%,
            #009121 75%,
            #81f305 100%
          );
          border-radius: 50px;
          margin: 0 2px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .streak-bonus-opacity {
          position: absolute;
          transition: width 0.5s ease;
          width: calc(${(props) => props.rollCnt > 15 ? 100 : (props.rollCnt / 15) * 100}% - ${props => props.rollCnt > 1 ? '25px' : '0px'});
          height: calc(100% - 4px);
          background: linear-gradient(
            90deg,
            rgba(204, 255, 0, 0) 0%,
            rgba(204, 255, 0, 0) 50%,
            rgba(204, 255, 0, 0.5) 75%,
            rgba(204, 255, 0, 1) 100%
          );
          border-radius: 50px;
          margin: 0 2px;
        }
        .streak-pay--leftside {
          position: relative;
          flex: 1;
          display: flex;
          align-items: center;
          .key-container {
            position: absolute;
            right: -52px;
            z-index: 3;
          }
        }
        .streak-pay--rightside {
          width: ${sizes.betWidth}px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-left: 60px;
        }
      }
    }
    .bet-pane-container {
      position: relative;
      width: ${sizes.mainWidth}px;
      height: ${sizes.mainWidth}px;
      background: url(${BetPane});
      background-size: 100% 100%;
      margin: 20px 0;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      .bet-ins-mask {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        min-height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        padding: 20px 40px;
        .bet-ins-mask-content {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          .fake-dice {
            position: absolute;
            left: 0;
            top: 77px;
          }
          .handler-bet {
            position: absolute;
            bottom: -41px;
          }
        }
      }
      .bet-pane-title {
        font-size: 22px;
        /* padding: 0 33px; */
        color: #ffff45;
        background: linear-gradient(
          360deg,
          #fbe741 25%,
          #dba323 53%,
          #fbe741 72%
        );
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        font-weight: 900;
        font-family: "Roboto";
        text-align: center;
        filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
        text-transform: uppercase;
        /* z-index: 1001; */
        /* z-index: 1; */
      }
      .bet-pane-description {
        color: white;
        font-size: 18px;
        font-family: "Roboto";
        text-align: center;
      }
      span {
        font-family: "Roboto";
        font-weight: 700;
        color: white;
        margin-bottom: 9px;
      }
      .one-two-strikes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        margin-bottom: 20px;
        .bet-pane-card-title {
          font-size: 16px;
          color: #ffff45;
          background: linear-gradient(
            360deg,
            #fbe741 25%,
            #dba323 53%,
            #fbe741 72%
          );
          -webkit-text-fill-color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          font-weight: 900;
          font-family: "Roboto";
          text-align: center;
          filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
          text-transform: uppercase;
          z-index: 1001;
        }
        .one-strike-wrapper {
          flex: 1;
          .one-strike {
            height: 60px;
            background: url(${OneStrike});
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 20px;
          }
        }
        .two-strike-wrapper {
          flex: 1;
          .two-strike {
            height: 60px;
            background: url(${TwoStrike});
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 20px;
          }
        }
      }
      .no-strikes {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 55px;
        span {
          font-size: 30px;
        }
        .no-strike-bg {
          width: 80%;
          height: 80px;
          background: url(${NoStrike});
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }
    .pane-container {
      position: relative;
      width: ${sizes.mainWidth}px;
      height: ${sizes.mainWidth}px;
      background: url(${Pane});
      background-size: 100% 100%;
      // margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      .free-pane {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background-color: rgba(0, 0, 0, 0.6);
      }
      .stop-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 3;
      }
      .covered-mask {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 5;
        color: white;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 1.4px;
      }
      .ins-mask {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        min-height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        padding: 40px;
        .ins-mask-header {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          .icon-bone {
            position: absolute;
            left: 0px;
          }
          .ins-mask-title {
            @media (max-width: ${mixins.mobileWidth}px) {
              font-size: 22px !important;
            }
          }
        }
        .btn-gotit {
          margin-top: 15px;
          cursor: pointer;
        }
      }
      .skipping-stars {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .pane {
        padding: 15px;
        .ant-row {
          .ant-col {
            &.rolled-num {
              z-index: 4 !important;
            }

            &.status-3 {
              z-index: 1 !important;
            }
          }
        }
      }
      .pane-mask {
        position: absolute;
        bottom: -20px;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 10px;
        border: solid dimgray 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 44px;
      }
      div.game-over {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0, 0.6);

        .game-over-center {
          position: relative;

          img {
            width: 283px;
            height: 180px;
          }

          .title {
            position: absolute;
            top: 0;
            text-align: center;
            width: 100%;
            font-weight: bold;
            color: #ffffff;
            font-size: 22px;
            margin-top: 35px;
            user-select: none;
          }

          .message {
            position: absolute;
            width: 100%;
            bottom: 38px;
            font-size: 35px;
            font-weight: bold;
            color: ${colors.yellow};
            text-align: center;
            user-select: none;
          }
          .message-es {
            line-height: 1;
            top: 86px;
            font-size: 29px;
          }
          .message-pt {
            line-height: 1;
            top: 81px;
          }
        }
      }
    }
    .dice {
      display: flex;
      justify-content: center;
      .die-container {
        .die {
          transition: transform 0.9s ease;
          .face {
            box-shadow: inset -0.35rem 0.35rem 0.75rem rgba(0, 0, 0, 0.3),
              inset 0.5rem -0.25rem 0.5rem rgba(0, 0, 0, 0.15);
            border-radius: 13px;
            border: solid 1px white;
          }
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      min-height: 105px;
      .guide-img,
      .paytable-img {
        min-width: 50px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
    .instruction-container {
      width: 100%;
      /* position: absolute; */
      /* bottom: 80px; */
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 10px;
      border-radius: 10px;
      .toggle-wrapper-md {
        display: flex;
        flex-direction: column;
        align-items: center;
        h5 {
          color: white;
          font-size: 14px;
        }
      }
      .toggle-wrapper-sm {
        display: none;
      }
      .icon-music {
        font-size: 34px;
        color: white;
        cursor: pointer;
      }
      @media (max-height: 870px) {
        /* bottom: 25px; */
      }
      @media (max-height: 820px) {
        /* bottom: 0px; */
      }
    }
  }
  .first-covered-popup {
    position: absolute;
    background-image: url(${RollAgainDialog});
    background-size: 100% 100%;
    width: 224px;
    height: 128px;
    top: 30px;
    left: 20px;
  }
  .first-covered-popup-character {
    position: absolute;
    background-image: url(${RollAgainFemale});
    background-size: 100% 100%;
    width: 140px;
    height: 150px;
    top: 20px;
    right: 5px;
  }
  /* @media (max-width: 412px) {
    .rolling-wrapper {
      .btn-container {
        min-height: 85px;
        .roll-btn {

          width: 185px !important;
          height: 80px !important;
          span {
            font-size: 24px !important;
          }
        }
      }
    }
  } */
  @media (max-width: ${mixins.mobileWidth}px),
    (max-height: ${mixins.ipadHeight}px) {
    margin-top: 0px;
    .rolling-wrapper {
      .bars-container {
        .roll-bonus {
          font-size: 15px !important;
        }
        .streak-pay-container {
          margin-bottom: 0px;
          .streak-pay--leftside {
            .key-container {
              right: -37px;
              .ant-image {
                width: 43px !important;
              }
            }
          }
          .streak-pay--rightside {
            width: 90px;
            padding-left: 40px;
          }
          .streak-bonus-overlay {
            transition: width 0.5s ease;
            width: calc(${(props) => props.rollCnt > 15 ? 100 : (props.rollCnt / 15) * 100}% - ${props => props.rollCnt > 1 ? '25px' : '0px'});
            height: calc(100% - 4px);
            background-image: linear-gradient(
              0deg,
              #81f305 0%,
              #009900 50%,
              #009121 75%,
              #81f305 100%
            );
            border-radius: 50px;
            margin: 0 2px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .streak-bonus-opacity {
            transition: width 0.5s ease;
            height: calc(100% - 4px);
            background: linear-gradient(
              90deg,
              rgba(204, 255, 0, 0) 0%,
              rgba(204, 255, 0, 0) 50%,
              rgba(204, 255, 0, 0.5) 75%,
              rgba(204, 255, 0, 1) 100%
            );
            border-radius: 50px;
            margin: 0 2px;
          }
        }
      }
      .pane-container {
        .pane-mask {
          bottom: -8px;
          padding: 6px 44px;
          span {
            font-size: 14px !important;
          }
        }
      }
      .bet-pane-container {
        width: ${sizes.mainWidthM}px;
        height: ${sizes.mainWidthM}px;
        .bet-pane-title {
          font-size: 20px;
        }
        .no-strikes {
          margin-bottom: 25px;
        }
      }
      .holes-container {
        user-select: none;
        margin-top: 12px;
        .holes-mask {
          top: -36px;
          span {
            font-size: 12px !important;
          }
        }
      }
      .btn-container {
        min-height: 58px;
        .roll-btn {
          animation: sign-animation 0s 0.1s steps(57) forwards;
        }
      }
      .instruction-container {
        padding: 0 10px;
        .toggle-wrapper-md {
          display: none;
        }
        .toggle-wrapper-sm {
          display: block;
        }
      }
    }
  }

`;

export const BottomContents = styled.div`
  position: relative;
  height: 250px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
  z-index: 2;
`;

export const SpeechBubble = styled.div`
  position: absolute;
  left: -5%;
  top: 65%;
  height: 240px;
  width: 174px;
  background-image: url(${SpeechBubbleImage});
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  transition: all 300ms;
  opacity: 0;

  div {
    position: relative;
    top: 56%;
    left: 20%;
    width: 100px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.3;
    &.enfontsize {
      font-size: 19px;
      height: 100px;
      top: 52%;
      width: 121px;
      left: 15%;
    }
  }


  span {
    color: #d06f07;
    font-weight: bold;
  }
  &.show {
    opacity: 1;
  }
`

export const SideBetContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  &::nth-child(2) {
    justify-content: end;
  }
`;

export const SideBetButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 85px;
  width: 170px;
`;

export const SideBetButton = styled.button`
  display: flex;
  background-image: url(${props => props.accept ? SideBetAccept : SideBetDecline});
  background-size: 107px 111px;
  background-position: -13px -5px;
  background-color: transparent;
  width: 80px;
  height: 80px;
  font-size: 10pt;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  color: #000;
  font-family: "Barlow Semi Condensed Bold";
  font-weight: 700;
  cursor: pointer;
  border: 0;
  padding: 0 10px;

  &:hover {
    transform: scale(1.05) translateZ(0);
  }
`;

export const BottomToggles = styled.div`
  display: flex;
  align-items: center;

  .auto-pick-toggle {
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const MetalBeam = styled.div`
  display: none;
`